import "./src/style/global.scss";
// import "tailwindcss/dist/base.css";
import "./src/style/modal.scss";
import "./src/style/hamburger.scss";

import React from "react";
import CmsProvider from "./src/component/context/CmsContext";

export const wrapRootElement = ({ element }) => (
  <CmsProvider>{element}</CmsProvider>
);
