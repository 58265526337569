import { graphql, useStaticQuery } from "gatsby";
import React, { FC, ReactNode, createContext, useContext } from "react";

const query = graphql`
  query MyQuery {
    allMicrocmsBlog(sort: { createdAt: DESC }) {
      edges {
        node {
          id
          blogId
          createdAt(formatString: "YYYY/MM/DD")
          title
          content
          image {
            url
          }
        }
      }
    }
    # allInstagramContent(sort: { timestamp: DESC }) {
    #   edges {
    #     node {
    #       id
    #       permalink
    #       timestamp(formatString: "YYYY/MM/DD")
    #       caption
    #       localFile {
    #         childImageSharp {
    #           gatsbyImageData
    #         }
    #       }
    #     }
    #   }
    # }
    allMicrocmsHearing(sort: { createdAt: DESC }) {
      edges {
        node {
          category
          age
          content
          createdAt(formatString: "YYYY/MM/DD")
          hearingId
          image {
            url
          }
          name
          sex
          title
        }
      }
    }
    allMicrocmsCampaign {
      edges {
        node {
          campaignId
          campaignName
          courseName
          explain
          price
          usualPrice
          foot
        }
      }
    }
  }
`;

const defaultState: Queries.MyQueryQuery = {
  allMicrocmsBlog: {
    edges: [],
  },
  // allInstagramContent: {
  //   edges: [],
  // },
  allMicrocmsHearing: {
    edges: [],
  },
  allMicrocmsCampaign: {
    edges: [],
  },
};

const CmsContext = createContext(defaultState);

const CmsProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const cmsData = useStaticQuery<Queries.MyQueryQuery>(query);
  const value = cmsData;
  return <CmsContext.Provider value={value}>{children}</CmsContext.Provider>;
};

export function useCmsContext() {
  return useContext(CmsContext);
}

export default CmsProvider;
